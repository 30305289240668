<script setup>
import { useSettings } from "~/composables/useSettings";
import { useApp } from "~/composables/useApp";
import { chapterTitle } from "~/composables/useBible";

const { settings } = useSettings();
const { bibles } = useApp();

const availableStrong = computed(
  () => bibles.singleBible?.location.value?.bibleModel?.bibleStrong
);
</script>
<template>
  <div class="v-settings-panel" :class="settingsComputedClass">
    <!-- v-if="bibles.singleBible.location?.chapterNumIntoView" -->
    <div class="chapter">
      {{
        transl(
          chapterTitle(bibles.singleBible?.location.value?.bookModel?.bookCode),
          bibles.singleBible?.location.value?.bibleModel?.bibleLocale
        )
      }}
      {{ bibles.singleBible.location.value?.chapterNumIntoView }}
    </div>
    <div
      v-if="availableStrong"
      @click="
        useEvent('settings.update', {
          strong: !settings.enableStrong,
        })
      "
      class="settings-icon"
    >
      <img
        src="/icons/numeric.svg"
        alt=""
        :class="{ enableStrong: settings.enableStrong }"
        class="strong-icon"
      />
    </div>

    <div
      @click="useEvent('history.open')"
      class="settings-icon"
      style="padding-top: 3px"
    >
      <span><img src="/icons/clock.svg" alt="" /></span>
    </div>
    <div @click="useEvent('parallel.open')" class="settings-icon">
      <span><img src="/icons/two-columns.svg" alt="" /></span>
    </div>
    <div
      @click="useEvent('settings.open')"
      class="settings-icon"
      style="padding-top: 2px"
    >
      <span><img src="/icons/settings.svg" alt="" /></span>
    </div>
  </div>
</template>
<style scoped>
.chapter {
  margin-right: auto;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.v-settings-panel {
  height: 50px;
  border-radius: 5px;
  background: #fff;
  color: #9fa2b6;
  display: flex;
  justify-content: flex-end;
}
.settings-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 37px;
}
.strong-icon {
  width: 27px;
  filter: brightness(0) saturate(100%) invert(75%) sepia(3%) saturate(1301%)
    hue-rotate(195deg) brightness(88%) contrast(83%);
}
.enableStrong.strong-icon {
  filter: brightness(0) saturate(100%) invert(52%) sepia(94%) saturate(1124%)
    hue-rotate(130deg) brightness(90%) contrast(104%);
}
.settings-icon:hover {
  opacity: 0.8;
}
.settings-icon.name {
  justify-content: flex-start;
  font-size: 24px;
  font-weight: 600;
}

.enableStrong {
  color: var(--root-green);
}
</style>
